import { inject, Injectable } from '@angular/core';
import { ApolloBase, gql } from 'apollo-angular';

import { map, Observable } from 'rxjs';
import {
  TaskTemplate_Feature,
  TaskTemplate_Where,
  TaskTemplates,
} from '../generated';
import { RcApiService } from './rc-api.service';

interface TaskTemplatesVars {
  where?: TaskTemplate_Where;
  page?: number;
  limit?: number;
}

@Injectable()
export class TaskTemplatesService {
  private apollo: ApolloBase;

  private readonly rcApiService = inject(RcApiService);

  public readonly features: { [key in TaskTemplate_Feature]: string } = {
    basement: 'Basement',
    garage: 'Garage',
    hot_tub: 'Hot Tub',
    hot_water: 'Hot Water Heater',
    hvac: 'HVAC',
    kitchen: 'Kitchen',
    laundry: 'Laundry',
    lawn: 'Lawn & Irrigation',
    pool: 'Pool',
    structural: 'Structural',
    sump_pump: 'Sump Pump',
    water_softener: 'Water Softener',
  };

  constructor() {
    this.apollo = this.rcApiService.apollo;
  }

  public getTaskTemplates(
    variables?: TaskTemplatesVars,
  ): Observable<TaskTemplates> {
    return this.apollo
      .query<{ TaskTemplates: TaskTemplates }, TaskTemplatesVars>({
        query: gql`
          query TaskTemplatesList(
            $where: TaskTemplate_where
            $page: Int = 1
            $limit: Int = 10
          ) {
            TaskTemplates(where: $where, page: $page, limit: $limit) {
              docs {
                id
                title
                description
                thumbnail {
                  url
                }
                createdAt
                categories
                feature
                details
                schedule {
                  recurrence {
                    freq
                    interval
                    bymonth
                    bymonthday
                  }
                  due
                }
                assignment
                review {
                  required
                  evidence
                  approver
                }
                resources {
                  videos
                  articles
                }
              }
              totalDocs
              limit
              page
              totalPages
              hasNextPage
              hasPrevPage
              nextPage
              prevPage
            }
          }
        `,
        variables,
      })
      .pipe(map((result) => result?.data?.TaskTemplates || []));
  }
}
